import CompanyService from "@/services/company.service";
import LeadSource from '@/services/lead.source.service';
import ValidationsWebhook from "./ValidationsWebhook";
import ValidationsEmail from "./ValidationsEmail";

const service = new CompanyService();
const serviceSource = new LeadSource();

export default {
    name: 'LeasToCRM',
    components: {},
    props: [],
    data() {
        return {
            ForwardToExternal: [],
            forward:
                {
                    type: 'EMAIL',
                    url: '',
                    email: '',
                    format: 'ADF',
                    vehicleCondition: '',
                    sources: []
                },
            typeOptions: [
                {
                    value: 'EMAIL',
                    text: this.$t('email')
                },
                {
                    value: "WEBHOOK",
                    text: this.$t('webhook')
                }
            ],
            formatOptions: [
                {
                    value: 'ADF',
                    text: this.$t('adf')
                },
                {
                    value: "HTML",
                    text: this.$t('html')
                },
                {
                    value: "TEXT",
                    text: this.$t('text')
                }
            ],
            vehicleOptions: [
                {
                    value: 'NEW',
                    text: this.$t('new')
                },
                {
                    value: 'USED',
                    text: this.$t('used')
                },
                {
                    value: 'NEW & USED',
                    text: this.$t('new & used')
                }
            ],
            showCreateModal: false,
            showDeleteModal: false,
            showEditModal: false,
            selected: null,
            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            searchTerm: ''
        }
    },
    validations(){
        if(this.forward.type === "EMAIL")
            return ValidationsEmail
        return ValidationsWebhook
    },
    computed: {},
    async mounted() {
        await Promise.all([
            this.list(),
            this.listSources()
        ])
    },

    methods: {

        async list() {
            const res = await service.getForwardCRMSettings();
            if (res && !res.error) {
                this.ForwardToExternal = res.data || [];
            }
        },

        async listSources() {
            const res = await serviceSource.list();
            if(res && !res.error) {
                this.sources = res.data.sources;
            }
        },

        async add() {            
            await this.$v.$touch();
            if (await !this.$v.$invalid) {
                
                this.ForwardToExternal.push(this.forward);
                
                if (await this.save()) {
                    this.reset();
                    this.$notify({
                        type: 'success',
                        message: this.$t('settings_saved')
                    });
                } else {
                    this.$notify({
                        type: 'error',
                        message: this.$t('add_error')
                    });
                }
            }
        },

        async remove() {
            if (this.selected === null) return;     
            this.ForwardToExternal.splice(this.selected, 1);   

            const res = await service.updateForwardCRMSettings(this.ForwardToExternal);
            if (res && !res.error) {                    
                this.showDeleteModal = false;
                this.reset();
            this.$notify({
                type: 'success',
                message: this.$t('settings_delete')
            });
            } else {
                this.$notify({
                    type: 'error',
                    message: this.$t('remove_error')
                });
            }            
        },

        async edit() {
            if (this.selected === null) return;
            this.ForwardToExternal[this.selected] = this.forward;
    ``            
            await this.$v.$touch();
            if (await !this.$v.$invalid) {

                this.ForwardToExternal[this.selected] = this.forward;

                if (await this.save()) {
                    this.showEditModal = false;

                    this.reset();
                    this.$notify({
                        type: 'success',
                        message: this.$t('settings_edit')
                    });
                } else {
                    this.$notify({
                        type: 'error',
                        message: this.$t('edit_error')
                    });
                }
            }
        },

        async save() {
            const res = await service.updateForwardCRMSettings(this.ForwardToExternal);
            if (res && !res.error) {
                this.showCreateModal = false;
                return true;
            } else {
                return false;
            }
        },

        reset() {
            this.forward = {
                type: 'EMAIL',
                url: '',
                email: '',
                format: 'ADF',
                vehicleCondition: '',
                sources: []
            };
            this.selectedSource = '';
            this.selected = null;
            this.$v.$reset();
        },

        selectToDelete(index) {
            this.selected = index;
            this.showDeleteModal = true;
        },

        selectToEdit(index) {
            this.selected = index;
            this.forward = JSON.parse(JSON.stringify(this.ForwardToExternal[index]));
            this.showEditModal = true;
        }
    }
}
