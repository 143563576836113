import { required } from 'vuelidate/lib/validators';

const isEmail =     val => {    
    if(val && val !=="" && val.includes('+') === false)
        return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(val);
    return true;
}

const isDomainUrl = val => {
    if(val !== "")
        return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.([a-z]{2,6}){1}/g.test(val)
    return true;    
}


export default {
    forward: {
        type: {
            required
        },
        url: {
            isDomainUrl,
            required            
        },
        email: {
            isEmail,            
        },
        format: {
            required
        },
        vehicleCondition: {
            required
        },
        sources: {
            required
        }
    }
};